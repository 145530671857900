import {useLogout} from '@modules/auth/hooks/useLogout';
import {usePermittedForSp, useUserResourcesPermissions} from '@modules/hospital_users/hooks/useUserPermissions';
import React, {useEffect, useMemo} from 'react';

const NO_PERMISSION_USER_ALERT_MESSAGE =
  'このユーザーではスマートフォンからAssetにアクセスできません。機器一覧あるいは点検機能を利用できるユーザーでログインしてください。';
const NOT_ASSETS_USER_ALERT_MESSAGE =
  'このユーザーではAssetを利用できません。Asset利用設定済のユーザーでログインしてください。';
export const EmptyPage = () => {
  const logout = useLogout();

  const usePermissionAlertMessage = () => {
    const {canRead: canAccessAsset} = useUserResourcesPermissions('LOGIN');
    const {canReadProductList, canReadInspection} = usePermittedForSp();
    return useMemo(() => {
      if (!canAccessAsset) {
        return NOT_ASSETS_USER_ALERT_MESSAGE;
      }
      if (!canReadProductList && !canReadInspection) {
        return NO_PERMISSION_USER_ALERT_MESSAGE;
      }
      return null;
    }, [canAccessAsset, canReadProductList, canReadInspection]);
  };

  const alertMessage = usePermissionAlertMessage();

  useEffect(() => {
    if (alertMessage) {
      window.alert(alertMessage);
      logout();
    }
  }, [alertMessage]);

  return <></>;
};
