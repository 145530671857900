import {NotificationCategory, NotificationOptionFilter} from '@modules/notifications/types';

export const enabled =
  import.meta.env.VITE_APP_ENV !== 'local' || import.meta.env.VITE_DEBUG_USER_NOTIFICATION === 'true';

export const USER_NOTIFICATION_DRAWER_TITLE = '通知';
export const PMDA_NOTIFICATION_DRAWER_TITLE = 'PMDA関連通知';
// 通知の取得間隔30秒
export const NOTIFICATION_INTERVAL = 30000;

// バックグラウンド通知
const BACKGROUND_NOTIFICATIONS = {
  EXPORT_HOSPITAL_PRODUCT: 'export_hospital_product_notification',
  EXPORT_INSPECTION_RESULT: 'export_inspection_result_notification',
  EXPORT_TRAINING_PLAN: 'export_training_plan_notification',
  EXPORT_RENTALS: 'export_rentals_notification',
  EXPORT_INSPECTION_RESULTS_CSV: 'export_inspection_results_csv_notification',
  EXPORT_MAKER_INSPECTION_RESULTS: 'export_maker_inspection_results_notification',
  IMPORT_HOSPITAL_PRODUCT: 'import_hospital_product_notification',
  GENERATE_INSPECTION_RESULT_PDFS: 'generate_inspection_result_pdfs_notification',
  EXPORT_HOSPITAL_PRODUCT_UPDATE: 'export_hospital_product_update_notification',
  BULK_UPDATE_HOSPITAL_PRODUCT: 'bulk_update_hospital_product_notification',
} as const;

// ユーザー用の通知（バックグラウンド通知を除外）
export const USER_NOTIFICATIONS = {
  RENT_NOTIFICATION: 'rent_notification',
  RETURN_NOTIFICATION: 'return_notification',
  INSPECTION_REMINDER: 'inspection_reminder',
  FAULT_REPAIR_PIC_NOTIFICATION: 'fault_repair_pic_notification',
  FAULT_RECEPTION_NOTIFICATION: 'fault_reception_notification',
  RETURN_WAITING_RECEPTION_NOTIFICATION: 'return_waiting_reception_notification',
} as const;

// PMDA用の通知
const PMDA_NOTIFICATIONS = {
  UPLOAD: 'pmda_upload_notification',
  RECALL: 'pmda_recall_notification',
} as const;

export const backgroundUserNotificationCategories: NotificationCategory[] = Object.values(BACKGROUND_NOTIFICATIONS);
export const userNotificationCategories: NotificationCategory[] = Object.values(USER_NOTIFICATIONS);

export const userNotificationOptionFilters: NotificationOptionFilter[] = [
  {
    id: 'all',
    label: 'すべて選択',
    values: [...userNotificationCategories, ...backgroundUserNotificationCategories],
  },
  {
    id: 'rent_notification',
    values: [USER_NOTIFICATIONS.RENT_NOTIFICATION],
    label: '貸出',
  },
  {
    id: 'return_notification',
    values: [USER_NOTIFICATIONS.RETURN_NOTIFICATION],
    label: '返却',
  },
  {
    id: 'inspection_reminder',
    values: [USER_NOTIFICATIONS.INSPECTION_REMINDER],
    label: 'リマインダー',
  },
  {
    id: 'fault_repair_pic_notification',
    values: [USER_NOTIFICATIONS.FAULT_REPAIR_PIC_NOTIFICATION],
    label: '修理担当者の割り当て',
  },
  {
    id: 'background',
    values: [...backgroundUserNotificationCategories],
    label: 'バックグラウンド処理',
  },
  {
    id: 'fault_reception_notification',
    values: [USER_NOTIFICATIONS.FAULT_RECEPTION_NOTIFICATION],
    label: '不具合受付',
  },
  {
    id: 'return_waiting_reception_notification',
    values: [USER_NOTIFICATIONS.RETURN_WAITING_RECEPTION_NOTIFICATION],
    label: '返却待ち受付',
  },
];

export const pmdaNotificationCategories: NotificationCategory[] = Object.values(PMDA_NOTIFICATIONS);

export const pmdaNotificationOptionFilters: NotificationOptionFilter[] = [
  {
    id: 'all',
    label: 'すべて選択',
    values: pmdaNotificationCategories,
  },
  {
    id: 'pmda_upload_notification',
    values: [PMDA_NOTIFICATIONS.UPLOAD],
    label: '添付文書の更新',
  },
  {
    id: 'pmda_recall_notification',
    values: [PMDA_NOTIFICATIONS.RECALL],
    label: '回収情報の発出',
  },
];
