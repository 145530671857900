import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Notifications} from '@material-ui/icons';
import {Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import {Badge} from '@atoms/Badge';
import {useQuery} from 'react-query';
import {getNotifications} from '@modules/notifications/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {NotificationDrawer} from './NotificationDrawer';
import {NOTIFICATION_INTERVAL, USER_NOTIFICATION_DRAWER_TITLE, enabled} from './constants';

import {
  useFilteredUserNotificationOptionFilters,
  usePermittedUserNotificationCategories,
} from '@components/organisms/Header/pc/usePermittedNotification';

export const UserNotification: React.FC = () => {
  const classes = useStyles();
  const {myInfo} = useMyInfo();
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);

  const userNotificationCategories = usePermittedUserNotificationCategories();
  const userNotificationOptionFilters = useFilteredUserNotificationOptionFilters();
  const notificationCountQ = useQuery(
    ['userNotificationsCount', myInfo],
    () =>
      getNotifications(
        myInfo.hospitalHashId,
        myInfo.hashId,
        ['unread'],
        userNotificationCategories,
        null,
        null,
        0,
        100
      ),
    {
      enabled,
      // dataプロパティの変更があったときのみ再レンダリングする
      notifyOnChangeProps: ['data'],
    }
  );

  useEffect(() => {
    if (!enabled) return;
    const intervalId = setInterval(() => {
      notificationCountQ.refetch();
    }, NOTIFICATION_INTERVAL);

    return () => clearInterval(intervalId);
  }, [enabled, notificationCountQ]);

  const handleAllClear = useCallback(() => {
    notificationCountQ.refetch();
  }, [notificationCountQ]);

  const totalCount = useMemo(() => {
    console.log('notificationCountQ.data?.data', notificationCountQ.data?.data);
    const filterData = notificationCountQ.data?.data.filter((v) => userNotificationCategories.includes(v.category));
    return filterData?.length || 0;
  }, [notificationCountQ, userNotificationCategories]);

  const permittedUserNotificationCategories = useMemo(() => {
    return userNotificationCategories;
  }, [userNotificationCategories]);

  const permittedUserNotificationOptionFilters = useMemo(() => {
    return userNotificationOptionFilters;
  }, [userNotificationOptionFilters]);

  return (
    <>
      <Button
        color="inherit"
        className={classes.linkedButtonIcon}
        onClick={() => setOpenNotificationDrawer(true)}
        size="small">
        <Badge badgeContent={totalCount} color={'secondary'}>
          <Notifications className={classes.linkIcon} />
        </Badge>
      </Button>
      {openNotificationDrawer && (
        <NotificationDrawer
          open={openNotificationDrawer}
          onClose={() => setOpenNotificationDrawer(false)}
          onAllClear={handleAllClear}
          masterCategories={permittedUserNotificationCategories} //ユーザ通知、PMDA通知のマスターカテゴリー
          optionFilters={permittedUserNotificationOptionFilters}
          drawerTitle={USER_NOTIFICATION_DRAWER_TITLE}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkIcon: {
      color: theme.palette.grey[700],
      height: '100%',
    },

    linkedButtonIcon: {
      height: '100%',
      borderRadius: 0,
      paddingRight: '8px',
      paddingLeft: '8px',
      minWidth: '24px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  })
);
